import { SvgIcons } from "../../assets/icons/SvgIcons";
import { Typewriter } from "react-simple-typewriter";
import {
  ExpandAltOutlined,
  LikeFilled,
  DislikeFilled,
} from "@ant-design/icons";
import { Avatar } from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useState } from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import { base64AudioData } from "../constants/data";
import HtmlFragment from "./HtmlFragment";

const CBMessage = ({
  // handleType,
  refList,
  sendMessageToBot,
  openImageFullscreen,
  data,
  index,
  chatMsgs,
  isChatFullScreen,
  LikeBotMsg,
  dislikeBotMsg,
  isLastElementBotMsg,
}) => {
  const [isTypingCompleted, setTypingCompleted] = useState(false);

  return (
    <div
      class={`${data?.msgType} ${
        isChatFullScreen
          ? data?.msgType == "bot-msg"
            ? "bot-msg-fullscreen"
            : "user-msg-fullscreen"
          : ""
      }`}
      key={index}
      ref={refList[index]}
    >
      {data?.msgType == "bot-msg" && (
        <div
          className="bot-msg-icon"
          style={{
            visibility:
              chatMsgs[index - 1]?.msgType == "bot-msg" ? "hidden" : "",
          }}
        >
          <Avatar icon={SvgIcons.dexIconSm} style={{ background: "#04224b" }} />
        </div>
      )}

      <div>
        {data?.text && (
          <p class="msg">
            {index == chatMsgs?.length - 1 && data?.msgType == "bot-msg" ? (
              // let ok = ""
              <>
                <div className="cb-markdown">
                  <ReactMarkdown
                    children={data.text}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
                {/* {isTypingCompleted ? (
                  <ReactMarkdown
                    children={data.text}
                    remarkPlugins={[remarkGfm]}
                  />
                ) : (
                  <Typewriter
                    words={[data?.text]}
                    cursor={false}
                    cursorStyle="_"
                    typeSpeed={30}
                    deleteSpeed={50}
                    delaySpeed={1000}
                    onType={(count) => handleType(count, data?.text)}
                  />
                )} */}
              </>
            ) : (
              <div className="cb-markdown">
                <ReactMarkdown
                  children={data.text}
                  remarkPlugins={[remarkGfm]}
                />
              </div>
            )}
            {data?.msgType == "bot-msg" && index !== 0 ? (
              <div className="cb-like-dislike-bot-msg mt-2">
                <div className="cursor-pointer">
                  <LikeFilled
                    onClick={() => LikeBotMsg(data?.messageId)}
                    className="cursor-pointer"
                    style={{ color: "#049ff3" }}
                  />
                </div>
                <div
                  onClick={() => dislikeBotMsg(data?.messageId)}
                  className="cursor-pointer"
                  style={{ color: "#049ff3" }}
                >
                  <DislikeFilled />
                </div>
              </div>
            ) : null}
          </p>
        )}
        {data?.voice && isLastElementBotMsg && (
          // <div className="cb-msg-audio-player">
          <AudioPlayer
            base64Audio={data?.voice}
            isNewBotMsg={isLastElementBotMsg}
          />
          // </div>
        )}
        {data?.buttons?.text && <p class="msg">{data?.buttons?.text}</p>}
        {data?.buttons?.buttons?.length > 0 && (
          <>
            <div class="response-btns">
              {data?.buttons?.buttons?.map((btnData) => {
                return (
                  <button
                    onClick={() =>
                      sendMessageToBot(
                        btnData?.label,
                        data?.id,
                        btnData?.payload,
                        true
                      )
                    }
                    class="btn-primary"
                  >
                    {btnData?.label}
                  </button>
                );
              })}
            </div>
          </>
        )}
        {data?.msgType == "bot-msg" && data?.image?.length > 0 && (
          <div className="chat-bot-msg-image">
            <img class="msg-image" src={data?.image} />
            <div
              className="chat-bot-fullscreen-icon"
              onClick={() => openImageFullscreen(data?.image)}
            >
              <ExpandAltOutlined />
            </div>
          </div>
        )}
        {data?.html && <HtmlFragment content={data?.html} />}
      </div>
    </div>
  );
};

export default CBMessage;
