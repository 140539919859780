import CBTypingLoader from "./CBTypingLoader";
import CBMessage from "./CBMessage";

const CBChatBody = ({
  handleType,
  chatMsgs,
  refList,
  sendMessageToBot,
  openImageFullscreen,
  responseLoading,
  isChatFullScreen,
  LikeBotMsg,
  dislikeBotMsg,
}) => {
  return (
    <div class="chat-area">
      {chatMsgs?.map((data, index) => {
        const isLastElementBotMsg =
          index == chatMsgs?.length - 1 && data?.msgType == "bot-msg"
            ? true
            : false;

        if (data?.reqThroughButton) return null;
        if (data?.voice && !isLastElementBotMsg) return null;
        // if (!data?.text && !data?.buttons?.buttons?.length) return null;
        if (data?.dex_understanding) return null;

        return (
          <CBMessage
            handleType={handleType}
            refList={refList}
            sendMessageToBot={sendMessageToBot}
            openImageFullscreen={openImageFullscreen}
            data={data}
            index={index}
            chatMsgs={chatMsgs}
            isChatFullScreen={isChatFullScreen}
            LikeBotMsg={LikeBotMsg}
            dislikeBotMsg={dislikeBotMsg}
            isLastElementBotMsg={isLastElementBotMsg}
          />
        );
      })}
      {responseLoading && <CBTypingLoader />}
    </div>
  );
};

export default CBChatBody;
