import axios from "axios";
import { createRef, useEffect, useState } from "react";
import { uid } from "uid";
import { useSelector } from "react-redux";
import { postTheMessage } from "../actions/chat/chat.action";
import { errorNotification, infoNotification } from "../services/notification";

export const useChatBotDashboard = () => {
  //Token
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const userDetails = useSelector((state) => state.login.userDetails);
  const userName = userDetails?.username ? userDetails?.username : "";

  //States
  const [isBotModal, setBotModal] = useState(false);
  const [chatMsgs, setChatMsgs] = useState([]);
  const [userMsg, setUserMsg] = useState("");
  const [refList, setRefList] = useState([]);
  const [isOpenImage, setOpenImage] = useState(false);
  const [fullScreenImageSrc, setFullscrennImgSrc] = useState("");
  const [responseLoading, setResponseLoading] = useState(false);
  const [isChatFullScreen, setChatFullScreen] = useState(true);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const [threadId, setThreadId] = useState(null);

  //UserDetails Mapping
  const userDetailsMapping = {
    "shilpi.chowdhary@lighthouse-canton.com": "Management",
    "audrey.tang@lighthouse-canton.com": "Management",
    "sunil.garg@lighthouse-canton.com": "Management",
    "payal.devgan@lighthouse-canton.com": "RM",
    "yinghui.lim@lighthouse-canton.com": "RM",
    "charlene.lin@lighthouse-canton.com": "RM",
    "wesley.chai@lighthouse-canton.com": "ARM",
    "rakesh.nathan@lighthouse-canton.com": "ARM",
    "antoine.bracq@lighthouse-canton.com": "IC",
    "joydeb.chatterjee@lighthouse-canton.com": "IC",
    "dinesh.gogia@lighthouse-canton.com": "Compliance",
    "shaheel.serajudeen@lighthouse-canton.com": "Ops",
  };

  //Login
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const handleLoginChange = (name, value) => {
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const onLoginSubmit = () => {
    // if (loginDetails?.email && loginDetails?.password) {
    const loginEmailId = loginDetails?.email?.trim().toLowerCase();
    const accessType = userDetails?.accessType ? userDetails?.accessType : "";

    // const userRole = userDetailsMapping[loginEmailId]
    //   ? userDetailsMapping[loginEmailId]
    //   : "Client";
    setChatMsgs([
      {
        text: `Hey ${userName}, I am DEX, how can i help you?`,
        msgType: "bot-msg",
        sender: "bot",
        buttons: [],
        image: "",
        id: uid(32),
      },
    ]);

    setSelectedUserRole(accessType);
    setUserLoggedIn(true);
    // }
  };

  const onLogout = () => {
    setChatMsgs([]);
    setLoginDetails({
      email: "",
      password: "",
    });
    setSelectedUserRole("");
    setUserLoggedIn(false);
    closeChatFullScreen();
  };

  //Open and close chat full screen
  const openChatFullScreen = () => setChatFullScreen(true);
  const closeChatFullScreen = () => setChatFullScreen(false);

  //Open Image Full Screen
  const openImageFullscreen = (src) => {
    setOpenImage(true);
    setFullscrennImgSrc(src);
  };

  //Close Image Full Screen
  const closeImageFullscreen = (src) => {
    setOpenImage(false);
  };

  //On user role change
  const onUserRoleChange = (value) => {
    setSelectedUserRole(value);
    setChatMsgs([]);
  };

  //Show Chat Pop Up to start chatting
  const showChatBot = () => {
    setBotModal(!isBotModal);
  };

  const closeChatBot = () => {
    setBotModal(false);
  };

  const loginChatBot = () => {
    setBotModal(true);
  };

  //Will be used in future, do not comment it out
  const handleType = (count, text) => {};

  //Store what user is typing
  const recordUserMessage = (value) => {
    setUserMsg(value);
  };

  console.log({ chatMsgs });
  //[SEND MESSAGE] Below in the Important Function *****
  //This is where user sends message and on bot reply the response is append with previous user message in setChatMsgs
  const sendMessageToBot = async (
    msg,
    isId,
    msgPayload = null,
    isButtonRes = null
  ) => {
    if (msg?.trim() == "") {
      infoNotification("Provide any information");
      return;
    }

    let currentMessages = [...chatMsgs];

    if (isId) {
      currentMessages = chatMsgs?.map((data) => {
        if (data?.id == isId) {
          return { ...data, buttons: [], reqThroughButton: true };
        }
        return data;
      });
    }

    const userMessage = {
      text: msg,
      msgType: "user-msg",
      sender: "raj",
      buttons: [],
      image: "",
      id: uid(32),
    };

    setChatMsgs([...currentMessages, userMessage]);

    const senderRole = selectedUserRole
      ? selectedUserRole?.toLowerCase()
      : "user123";

    const payload = {
      sender_id: userDetails?.id,
      thread_id: threadId,
      message: isButtonRes ? msgPayload : msg,
      role: senderRole,
    };

    setResponseLoading(true);

    try {
      const botRes = await postTheMessage(payload);

      if (botRes?.status == 200) {
        const botData = botRes?.data?.response ? botRes?.data?.response : [];

        const messageId = botRes?.data?.message_id
          ? botRes?.data?.message_id
          : "";
        const threadId = botRes?.data?.thread_id
          ? botRes?.data?.thread_id
          : null;

        setThreadId(threadId);

        const restructureBotData = botData?.map((data) => {
          return { ...data, msgType: "bot-msg", id: uid(32), messageId };
        });
        const previousMessages = [
          ...currentMessages,
          userMessage,
          ...restructureBotData,
        ];
        setChatMsgs(previousMessages);
      }
      setResponseLoading(false);
    } catch (error) {
      setResponseLoading(false);
      errorNotification("Something went wrong, please try again!!");
    }
  };

  //Below two useEffects makes sure that the latest message is focused in the chat room
  useEffect(() => {
    if (chatMsgs?.length) {
      const arr: any = [];
      chatMsgs?.map((_, i) => (arr[i] = createRef()));
      setRefList(arr);
    }
  }, [chatMsgs]);

  useEffect(() => {
    refList &&
      refList[refList?.length - 1] &&
      refList[refList?.length - 1].current?.scrollIntoView({
        behaviour: "smooth",
      });
  }, [refList]);

  useEffect(() => {
    if (isLoggedIn) {
      onLoginSubmit();
      setBotModal(true);
    } else {
      isBotModal && setBotModal(false);
      setChatMsgs([]);
      // isChatFullScreen && closeChatFullScreen();
    }
  }, [isLoggedIn]);

  return {
    isBotModal,
    chatMsgs,
    userMsg,
    refList,
    isOpenImage,
    fullScreenImageSrc,
    responseLoading,
    openImageFullscreen,
    closeImageFullscreen,
    showChatBot,
    handleType,
    sendMessageToBot,
    recordUserMessage,
    closeChatFullScreen,
    openChatFullScreen,
    isChatFullScreen,
    selectedUserRole,
    onUserRoleChange,
    isUserLoggedIn,
    loginDetails,
    handleLoginChange,
    onLoginSubmit,
    onLogout,
    loginChatBot,
    isLoggedIn,
    closeChatBot,
    // isAuthenticated,
  };
};
