import React, { useCallback, useEffect, useState } from "react";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { debounce } from "../../services/commonService";

const CBChatFooter = ({ recordUserMessage, sendMessageToBot, userMsg }) => {
  const [isVoiceAssistant, setVoiceAssistant] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const clearTranscript = () => {
    recordUserMessage("");
    resetTranscript();
    setVoiceAssistant(false);
  };

  const optimizedFunc = useCallback(
    debounce(sendMessageToBot, 2000, clearTranscript),
    []
  );

  const debounceVoiceAssistantMsg = () => {
    // recordUserMessage(transcript);
    // if (transcript) {
    //   optimizedFunc(transcript);
    // }
  };

  useEffect(() => {
    // debounceVoiceAssistantMsg();
    recordUserMessage(transcript);
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const onVoiceAssitant = () => {
    setVoiceAssistant(true);
    SpeechRecognition.startListening();
  };

  const OffVoiceAssistant = () => {
    setVoiceAssistant(false);
    resetTranscript();
  };

  const sendMessage = () => {
    sendMessageToBot(userMsg);
    recordUserMessage("");
    if (transcript) {
      resetTranscript();
      setVoiceAssistant(false);
    }
  };

  return (
    <div class="chat-input-area">
      <input
        type="text"
        autofocus
        value={userMsg}
        class="chat-input"
        placeholder="Type a message ..."
        autocomplete="off"
        onChange={(event) => {
          const { value } = event.target;
          recordUserMessage(value);
        }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            sendMessage();
          }
        }}
      />
      <div className="cb-chat-voice-icon">
        {isVoiceAssistant ? (
          <BsFillMicFill size="25" onClick={() => OffVoiceAssistant()} />
        ) : (
          <BsFillMicMuteFill size="25" onClick={() => onVoiceAssitant()} />
        )}
      </div>
      <button
        class="chat-submit"
        onClick={() => {
          sendMessage();
        }}
      >
        Send
      </button>
    </div>
  );
};

export default CBChatFooter;
